import * as client        from './client';
import * as exam          from './exam';
import * as field         from './field';
import * as form          from './form';
import * as patient       from './patient';
import * as professional  from './professional';
import * as category from './category';
import * as favorites from './favorites';
import * as qualifier from './qualifiers';
import * as categoriesFiles from './categoriesFiles';

export default ({
  client,
  exam,
  field,
  form,
  patient,
  professional,
  category,
  favorites,
  qualifier,
  categoriesFiles,
});