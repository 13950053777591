export default ({
  isFirstLoading: true,
  hideAttachPatientModel: false,
  formOrigin: null,
  error: {
    done    : false,
    messages: []
  },
  fetching: {
    getListFields: false,
    getModelById: false,
    saveVersionField: false,
    saveForm: false,
    getDraftById: false,
    saveDraft: false,
    updateConfigForm: false,
  },
  listFields: {
    items: {},
    customIds: [],
    customIdsOriginal: [],
    managerIdsOriginal: [],
    managerIds: [],
  },
  fields: [],
  api: {
    getListFields: [],
    getListFieldsManager: []
  },
  configForm: {},
  imagesField: {},
  dynamicForms: { //exemplo do objeto em assets/examples/dynamicForms.js
    draftId: '',
    fields: {},
    modules: {},
    sections: {},
    forms: {
      idForm: '',
      name: '',
      description: '',
      attachPatientModel: false,
      sectionsIds: []
    }
  },
  modal: {
    editField: {
      modalId: 'editField',
      name: '',
      visible: false,
      field: {},
      table: {},
      optionsFields: {},
      columnsIds: [],
      rowsIds: [],
      onSave: () => {},
      onClose: () => {},
      onOpen: () => {}
    }
  }
});

//Exemplo basico das propriedades da modal
// const modal = ({
//   'idModal': {
//     modalId: 'idModal',
//     name: 'Titulo da Modal',
//     done: false,
//     fields: {
//       'idField': {
//         id: '',
//         name: '',
//         size: 0,
//         dataType: 1,
//         optionsFieldsIds: [],
//         columnsIds: [],
//         rowsIds: [],
//         tableId: ''
//       },
//     },
//     optionsFields: {
//       'idOptionField': {
//         //property option
//       }
//     },
//     tables: {
//       'idTable': {
//         id: '',
//         name: '',
//         columnsIds: [],
//         rowsIds: []
//       }
//     },
//     columnsIds: [],
//     rowsIds: [],
//     onSave: () => {},
//     onClose: () => {},
//     onOpen: () => {}
//   }
// });