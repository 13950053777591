export default ({
  SECTION : 'patient',

  MODAL_ATTENDANCE      : 'modal:attendance',
  MODAL_ATTENDANCE_REGISTER_ABSENCE: 'modal:attendance:register:absence',
  MODAL_FORM_ATTENDANCE_ABSENCE : 'modal:form:attendance:absence',
  MODAL_FORM_ATTENDANCE : 'modal:form:attendance',
  MODAL_FORM_ATTENDANCE_ABSENCE : 'modal:form:attendance:absence',
  MODAL_DELETE_ATTENDANCE      : 'modal:delete:attendance',
  MODAL_DELETE_FORM      : 'modal:delete:form',
  MODAL_FORM_SEND: 'modal:form:send',

  MODAL_EXAM_HISTORY          : 'modal:exam:history',
  MODAL_EXAM_HISTORY_GENERAL  : 'modal:exam:history:general',

  MEDIA_VIDEOBOX : 'modal:video:box',

  SELECT_NEW_FORM_SECTION: 'SELECT_NEW_FORM_SECTION',
  SAVE_STATUS_BUTTON: 'SAVE_STATUS_BUTTON',

  MODAL_ALBUM_PHOTO_COMPARATIVE : 'modal:album:photo:comparative',
  MODAL_ALBUM_VIDEO_COMPARATIVE : 'modal:album:video:comparative',

  MODAL_ATTENDANCE_ACCESS_GROUP : 'modal:attendance:accessgroup',

  MEDICALRECORD_PATIENT_UPDATE_FORM_ANSWER_AND_SECTION : 'MEDICALRECORD_PATIENT_UPDATE_FORM_ANSWER_AND_SECTION',

  MEDICALRECORD_PATIENT_CHANGE_MODEL_FORM : 'MEDICALRECORD_PATIENT_CHANGE_MODEL_FORM',

  MEDICALRECORD_PATIENT_RESET_STATE                 : 'MEDICALRECORD_PATIENT_RESET_STATE',
  MEDICALRECORD_PATIENT_SET_SECTIONS_DRAWER         : 'MEDICALRECORD_PATIENT_SET_SECTIONS_DRAWER',
  MEDICALRECORD_PATIENT_SET_USER_INFO_DRAWER        : 'MEDICALRECORD_PATIENT_SET_USER_INFO_DRAWER',
  MEDICALRECORD_PATIENT_SET_TAB_SECTIONS            : 'MEDICALRECORD_PATIENT_SET_TAB_SECTIONS',
 

  MEDICALRECORD_PATIENT_SET_RELEASED_ATTENDANCES_PROFESSIONALS       : 'MEDICALRECORD_PATIENT_SET_RELEASED_ATTENDANCES_PROFESSIONALS',
  MEDICALRECORD_PATIENT_POST_ATTENDANCE_FETCH_REQUEST       : 'MEDICALRECORD_PATIENT_POST_ATTENDANCE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_POST_ATTENDANCE_FETCH_SUCCESS       : 'MEDICALRECORD_PATIENT_POST_ATTENDANCE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_POST_ATTENDANCE_FETCH_ERROR         : 'MEDICALRECORD_PATIENT_POST_ATTENDANCE_FETCH_ERROR',
  MEDICALRECORD_PATIENT_GET_ATTENDANCE_BY_ID_FETCH_REQUEST  : 'MEDICALRECORD_PATIENT_GET_ATTENDANCE_BY_ID_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_ATTENDANCE_BY_ID_FETCH_SUCCESS  : 'MEDICALRECORD_PATIENT_GET_ATTENDANCE_BY_ID_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_ATTENDANCE_BY_ID_FETCH_ERROR    : 'MEDICALRECORD_PATIENT_GET_ATTENDANCE_BY_ID_FETCH_ERROR',

  MEDICALRECORD_PATIENT_GET_LIST_PRESCRIPTION_MODEL_FETCH_REQUEST   : 'MEDICALRECORD_PATIENT_GET_LIST_PRESCRIPTION_MODEL_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_LIST_PRESCRIPTION_MODEL_FETCH_SUCCESS   : 'MEDICALRECORD_PATIENT_GET_LIST_PRESCRIPTION_MODEL_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_LIST_PRESCRIPTION_MODEL_FETCH_ERROR     : 'MEDICALRECORD_PATIENT_GET_LIST_PRESCRIPTION_MODEL_FETCH_ERROR',
  MEDICALRECORD_PATIENT_GET_PRESCRIPTION_MODEL_BY_ID_FETCH_REQUEST  : 'MEDICALRECORD_PATIENT_GET_PRESCRIPTION_MODEL_BY_ID_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_PRESCRIPTION_MODEL_BY_ID_FETCH_SUCCESS  : 'MEDICALRECORD_PATIENT_GET_PRESCRIPTION_MODEL_BY_ID_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_PRESCRIPTION_MODEL_BY_ID_FETCH_ERROR    : 'MEDICALRECORD_PATIENT_GET_PRESCRIPTION_MODEL_BY_ID_FETCH_ERROR',
  MEDICALRECORD_PATIENT_SAVE_PRESCRIPTION_FETCH_REQUEST             : 'MEDICALRECORD_PATIENT_SAVE_PRESCRIPTION_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_SAVE_PRESCRIPTION_FETCH_SUCCESS             : 'MEDICALRECORD_PATIENT_SAVE_PRESCRIPTION_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_SAVE_PRESCRIPTION_FETCH_ERROR               : 'MEDICALRECORD_PATIENT_SAVE_PRESCRIPTION_FETCH_ERROR',
  MEDICALRECORD_PATIENT_REMOVE_PRESCRIPTION_FETCH_REQUEST           : 'MEDICALRECORD_PATIENT_REMOVE_PRESCRIPTION_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_REMOVE_PRESCRIPTION_FETCH_SUCCESS           : 'MEDICALRECORD_PATIENT_REMOVE_PRESCRIPTION_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_REMOVE_PRESCRIPTION_FETCH_ERROR             : 'MEDICALRECORD_PATIENT_REMOVE_PRESCRIPTION_FETCH_ERROR',
  MEDICALRECORD_PATIENT_SET_PRESCRIPTION_NEW_DESCRIPTION_CONTENT    : 'MEDICALRECORD_PATIENT_SET_PRESCRIPTION_NEW_DESCRIPTION_CONTENT',
  MEDICALRECORD_PATIENT_SET_PRESCRIPTION_TEXTAREA                   : 'MEDICALRECORD_PATIENT_SET_PRESCRIPTION_TEXTAREA',
  MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_DELTA_FETCH_REQUEST       : 'MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_DELTA_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_DELTA_FETCH_SUCCESS       : 'MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_DELTA_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_DELTA_FETCH_ERROR         : 'MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_DELTA_FETCH_ERROR',

  MEDICALRECORD_PATIENT_GET_LIST_CERTIFICATE_MODEL_FETCH_REQUEST    : 'MEDICALRECORD_PATIENT_GET_LIST_CERTIFICATE_MODEL_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_LIST_CERTIFICATE_MODEL_FETCH_SUCCESS    : 'MEDICALRECORD_PATIENT_GET_LIST_CERTIFICATE_MODEL_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_LIST_CERTIFICATE_MODEL_FETCH_ERROR      : 'MEDICALRECORD_PATIENT_GET_LIST_CERTIFICATE_MODEL_FETCH_ERROR',
  MEDICALRECORD_PATIENT_GET_CERTIFICATE_MODEL_BY_ID_FETCH_REQUEST   : 'MEDICALRECORD_PATIENT_GET_CERTIFICATE_MODEL_BY_ID_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_CERTIFICATE_MODEL_BY_ID_FETCH_SUCCESS   : 'MEDICALRECORD_PATIENT_GET_CERTIFICATE_MODEL_BY_ID_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_CERTIFICATE_MODEL_BY_ID_FETCH_ERROR     : 'MEDICALRECORD_PATIENT_GET_CERTIFICATE_MODEL_BY_ID_FETCH_ERROR',
  MEDICALRECORD_PATIENT_REMOVE_CERTIFICATE_FETCH_REQUEST            : 'MEDICALRECORD_PATIENT_REMOVE_CERTIFICATE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_REMOVE_CERTIFICATE_FETCH_SUCCESS            : 'MEDICALRECORD_PATIENT_REMOVE_CERTIFICATE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_REMOVE_CERTIFICATE_FETCH_ERROR              : 'MEDICALRECORD_PATIENT_REMOVE_CERTIFICATE_FETCH_ERROR',
  MEDICALRECORD_PATIENT_SAVE_CERTIFICATE_FETCH_REQUEST              : 'MEDICALRECORD_PATIENT_SAVE_CERTIFICATE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_SAVE_CERTIFICATE_FETCH_SUCCESS              : 'MEDICALRECORD_PATIENT_SAVE_CERTIFICATE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_SAVE_CERTIFICATE_FETCH_ERROR                : 'MEDICALRECORD_PATIENT_SAVE_CERTIFICATE_FETCH_ERROR',
  MEDICALRECORD_PATIENT_SET_CERTIFICATE_TEXTAREA                    : 'MEDICALRECORD_PATIENT_SET_CERTIFICATE_TEXTAREA',
  MEDICALRECORD_PATIENT_SET_CERTIFICATE_NEW_DESCRIPTION_CONTENT     : 'MEDICALRECORD_PATIENT_SET_CERTIFICATE_NEW_DESCRIPTION_CONTENT',
  MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_DELTA_FETCH_REQUEST        : 'MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_DELTA_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_DELTA_FETCH_SUCCESS        : 'MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_DELTA_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_DELTA_FETCH_ERROR          : 'MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_DELTA_FETCH_ERROR',

  MEDICALRECORD_PATIENT_LOAD_MAIN_TIMELINE_FETCH_REQUEST            : 'MEDICALRECORD_PATIENT_LOAD_MAIN_TIMELINE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LOAD_MAIN_TIMELINE_FETCH_SUCCESS            : 'MEDICALRECORD_PATIENT_LOAD_MAIN_TIMELINE_FETCH_SUCCESS',
  MEDICALRECORD_LOAD_SECTION_TIMELINE_FETCH_SUCCESS                 : 'MEDICALRECORD_LOAD_SECTION_TIMELINE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_SECTION_FETCH_ERROR                         : 'MEDICALRECORD_PATIENT_SECTION_FETCH_ERROR',
  MEDICALRECORD_PATIENT_LOAD_MAIN_FILTER_TIMELINE_FETCH_SUCCESS     : 'MEDICALRECORD_PATIENT_LOAD_MAIN_FILTER_TIMELINE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_LOAD_MAIN_TIMELINE_FETCH_ERROR              : 'MEDICALRECORD_PATIENT_LOAD_MAIN_TIMELINE_FETCH_ERROR',

  MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_TIMELINE_FETCH_REQUEST            : 'MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_TIMELINE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_TIMELINE_FETCH_SUCCESS            : 'MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_TIMELINE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_TIMELINE_FETCH_ERROR              : 'MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_TIMELINE_FETCH_ERROR',

  MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_TIMELINE_FETCH_REQUEST            : 'MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_TIMELINE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_TIMELINE_FETCH_SUCCESS            : 'MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_TIMELINE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_TIMELINE_FETCH_ERROR              : 'MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_TIMELINE_FETCH_ERROR',

  MEDICALRECORD_PATIENT_LOAD_REFERRAL_TIMELINE_FETCH_REQUEST            : 'MEDICALRECORD_PATIENT_LOAD_REFERRAL_TIMELINE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LOAD_REFERRAL_TIMELINE_FETCH_SUCCESS            : 'MEDICALRECORD_PATIENT_LOAD_REFERRAL_TIMELINE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_LOAD_REFERRAL_TIMELINE_FETCH_ERROR              : 'MEDICALRECORD_PATIENT_LOAD_REFERRAL_TIMELINE_FETCH_ERROR',

  MEDICALRECORD_PATIENT_LOAD_REQUEST_TIMELINE_FETCH_REQUEST            : 'MEDICALRECORD_PATIENT_LOAD_REQUEST_TIMELINE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LOAD_REQUEST_TIMELINE_FETCH_SUCCESS            : 'MEDICALRECORD_PATIENT_LOAD_REQUEST_TIMELINE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_LOAD_REQUEST_TIMELINE_FETCH_ERROR              : 'MEDICALRECORD_PATIENT_LOAD_REQUEST_TIMELINE_FETCH_ERROR',

  MEDICALRECORD_PATIENT_LOAD_REPORT_TIMELINE_FETCH_REQUEST            : 'MEDICALRECORD_PATIENT_LOAD_REPORT_TIMELINE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LOAD_REPORT_TIMELINE_FETCH_SUCCESS            : 'MEDICALRECORD_PATIENT_LOAD_REPORT_TIMELINE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_LOAD_REPORT_TIMELINE_FETCH_ERROR              : 'MEDICALRECORD_PATIENT_LOAD_REPORT_TIMELINE_FETCH_ERROR',

  MEDICALRECORD_PATIENT_LOAD_MEDIA_TIMELINE_FETCH_REQUEST            : 'MEDICALRECORD_PATIENT_LOAD_MEDIA_TIMELINE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LOAD_MEDIA_TIMELINE_FETCH_SUCCESS            : 'MEDICALRECORD_PATIENT_LOAD_MEDIA_TIMELINE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_LOAD_MEDIA_TIMELINE_FETCH_ERROR              : 'MEDICALRECORD_PATIENT_LOAD_MEDIA_TIMELINE_FETCH_ERROR',

  MEDICALRECORD_PATIENT_LOAD_ATTACHMENT_TIMELINE_FETCH_REQUEST            : 'MEDICALRECORD_PATIENT_LOAD_ATTACHMENT_TIMELINE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LOAD_ATTACHMENT_TIMELINE_FETCH_SUCCESS            : 'MEDICALRECORD_PATIENT_LOAD_ATTACHMENT_TIMELINE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_LOAD_ATTACHMENT_TIMELINE_FETCH_ERROR              : 'MEDICALRECORD_PATIENT_LOAD_ATTACHMENT_TIMELINE_FETCH_ERROR',

  MEDICALRECORD_PATIENT_LOAD_EXAM_TIMELINE_FETCH_REQUEST            : 'MEDICALRECORD_PATIENT_LOAD_EXAM_TIMELINE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LOAD_EXAM_TIMELINE_FETCH_SUCCESS            : 'MEDICALRECORD_PATIENT_LOAD_EXAM_TIMELINE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_LOAD_EXAM_TIMELINE_FETCH_ERROR              : 'MEDICALRECORD_PATIENT_LOAD_EXAM_TIMELINE_FETCH_ERROR',

  MEDICALRECORD_PATIENT_LOAD_SECTION_TIMELINE_FETCH_REQUEST            : 'MEDICALRECORD_PATIENT_LOAD_SECTION_TIMELINE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LOAD_SECTION_TIMELINE_FETCH_SUCCESS            : 'MEDICALRECORD_PATIENT_LOAD_SECTION_TIMELINE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_LOAD_SECTION_TIMELINE_FETCH_ERROR              : 'MEDICALRECORD_PATIENT_LOAD_SECTION_TIMELINE_FETCH_ERROR',

  MEDICALRECORD_PATIENT_CREATE_SECTION_TIMELINE_DATA              : 'MEDICALRECORD_PATIENT_CREATE_SECTION_TIMELINE_DATA',

  MEDICALRECORD_PATIENT_SET_PATIENT_ID                              : 'MEDICALRECORD_PATIENT_SET_PATIENT_ID',

  MEDICALRECORD_PATIENT_MEDIA_IMAGE_DOWNLOAD_FETCH_REQUEST         : 'MEDICALRECORD_PATIENT_MEDIA_IMAGE_DOWNLOAD_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_MEDIA_IMAGE_DOWNLOAD_FETCH_ERROR           : 'MEDICALRECORD_PATIENT_MEDIA_IMAGE_DOWNLOAD_FETCH_ERROR',
  MEDICALRECORD_PATIENT_MEDIA_IMAGE_DOWNLOAD_FETCH_SUCCESS         : 'MEDICALRECORD_PATIENT_MEDIA_IMAGE_DOWNLOAD_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_MEDIA_IMAGE_GET_FORM_FIELD_FETCH_SUCCESS  : 'MEDICALRECORD_PATIENT_MEDIA_IMAGE_GET_FORM_FIELD_FETCH_SUCCESS',

  MEDICALRECORD_PATIENT_MEDIA_ATTACHMENT_DOWNLOAD_FETCH_REQUEST         : 'MEDICALRECORD_PATIENT_MEDIA_ATTACHMENT_DOWNLOAD_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_MEDIA_ATTACHMENT_DOWNLOAD_FETCH_ERROR           : 'MEDICALRECORD_PATIENT_MEDIA_ATTACHMENT_DOWNLOAD_FETCH_ERROR',
  MEDICALRECORD_PATIENT_MEDIA_ATTACHMENT_DOWNLOAD_FETCH_SUCCESS         : 'MEDICALRECORD_PATIENT_MEDIA_ATTACHMENT_DOWNLOAD_FETCH_SUCCESS',

  MEDICALRECORD_PATIENT_GET_LIST_REPORT_MODEL_FETCH_REQUEST    : 'MEDICALRECORD_PATIENT_GET_LIST_REPORT_MODEL_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_LIST_REPORT_MODEL_FETCH_SUCCESS    : 'MEDICALRECORD_PATIENT_GET_LIST_REPORT_MODEL_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_LIST_REPORT_MODEL_FETCH_ERROR      : 'MEDICALRECORD_PATIENT_GET_LIST_REPORT_MODEL_FETCH_ERROR',
  MEDICALRECORD_PATIENT_GET_REPORT_MODEL_BY_ID_FETCH_REQUEST   : 'MEDICALRECORD_PATIENT_GET_REPORT_MODEL_BY_ID_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_REPORT_MODEL_BY_ID_FETCH_SUCCESS   : 'MEDICALRECORD_PATIENT_GET_REPORT_MODEL_BY_ID_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_REPORT_MODEL_BY_ID_FETCH_ERROR     : 'MEDICALRECORD_PATIENT_GET_REPORT_MODEL_BY_ID_FETCH_ERROR',
  MEDICALRECORD_PATIENT_REMOVE_REPORT_FETCH_REQUEST            : 'MEDICALRECORD_PATIENT_REMOVE_REPORT_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_REMOVE_REPORT_FETCH_SUCCESS            : 'MEDICALRECORD_PATIENT_REMOVE_REPORT_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_REMOVE_REPORT_FETCH_ERROR              : 'MEDICALRECORD_PATIENT_REMOVE_REPORT_FETCH_ERROR',
  MEDICALRECORD_PATIENT_SAVE_REPORT_FETCH_REQUEST              : 'MEDICALRECORD_PATIENT_SAVE_REPORT_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_SAVE_REPORT_FETCH_SUCCESS              : 'MEDICALRECORD_PATIENT_SAVE_REPORT_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_SAVE_REPORT_FETCH_ERROR                : 'MEDICALRECORD_PATIENT_SAVE_REPORT_FETCH_ERROR',
  MEDICALRECORD_PATIENT_SET_REPORT_TEXTAREA                    : 'MEDICALRECORD_PATIENT_SET_REPORT_TEXTAREA',
  MEDICALRECORD_PATIENT_SET_REPORT_NEW_DESCRIPTION_CONTENT     : 'MEDICALRECORD_PATIENT_SET_REPORT_NEW_DESCRIPTION_CONTENT',
  MEDICALRECORD_PATIENT_LOAD_REPORT_DELTA_FETCH_REQUEST        : 'MEDICALRECORD_PATIENT_LOAD_REPORT_DELTA_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LOAD_REPORT_DELTA_FETCH_SUCCESS        : 'MEDICALRECORD_PATIENT_LOAD_REPORT_DELTA_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_LOAD_REPORT_DELTA_FETCH_ERROR          : 'MEDICALRECORD_PATIENT_LOAD_REPORT_DELTA_FETCH_ERROR',

  MEDICALRECORD_PATIENT_GET_LIST_REQUEST_MODEL_FETCH_REQUEST    : 'MEDICALRECORD_PATIENT_GET_LIST_REQUEST_MODEL_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_LIST_REQUEST_MODEL_FETCH_SUCCESS    : 'MEDICALRECORD_PATIENT_GET_LIST_REQUEST_MODEL_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_LIST_REQUEST_MODEL_FETCH_ERROR      : 'MEDICALRECORD_PATIENT_GET_LIST_REQUEST_MODEL_FETCH_ERROR',
  MEDICALRECORD_PATIENT_GET_REQUEST_MODEL_BY_ID_FETCH_REQUEST   : 'MEDICALRECORD_PATIENT_GET_REQUEST_MODEL_BY_ID_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_REQUEST_MODEL_BY_ID_FETCH_SUCCESS   : 'MEDICALRECORD_PATIENT_GET_REQUEST_MODEL_BY_ID_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_REQUEST_MODEL_BY_ID_FETCH_ERROR     : 'MEDICALRECORD_PATIENT_GET_REQUEST_MODEL_BY_ID_FETCH_ERROR',
  MEDICALRECORD_PATIENT_REMOVE_REQUEST_FETCH_REQUEST            : 'MEDICALRECORD_PATIENT_REMOVE_REQUEST_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_REMOVE_REQUEST_FETCH_SUCCESS            : 'MEDICALRECORD_PATIENT_REMOVE_REQUEST_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_REMOVE_REQUEST_FETCH_ERROR              : 'MEDICALRECORD_PATIENT_REMOVE_REQUEST_FETCH_ERROR',
  MEDICALRECORD_PATIENT_SAVE_REQUEST_FETCH_REQUEST              : 'MEDICALRECORD_PATIENT_SAVE_REQUEST_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_SAVE_REQUEST_FETCH_SUCCESS              : 'MEDICALRECORD_PATIENT_SAVE_REQUEST_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_SAVE_REQUEST_FETCH_ERROR                : 'MEDICALRECORD_PATIENT_SAVE_REQUEST_FETCH_ERROR',
  MEDICALRECORD_PATIENT_SET_REQUEST_TEXTAREA                    : 'MEDICALRECORD_PATIENT_SET_REQUEST_TEXTAREA',
  MEDICALRECORD_PATIENT_SET_REQUEST_NEW_DESCRIPTION_CONTENT     : 'MEDICALRECORD_PATIENT_SET_REQUEST_NEW_DESCRIPTION_CONTENT',
  MEDICALRECORD_PATIENT_LOAD_REQUEST_DELTA_FETCH_REQUEST        : 'MEDICALRECORD_PATIENT_LOAD_REQUEST_DELTA_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LOAD_REQUEST_DELTA_FETCH_SUCCESS        : 'MEDICALRECORD_PATIENT_LOAD_REQUEST_DELTA_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_LOAD_REQUEST_DELTA_FETCH_ERROR          : 'MEDICALRECORD_PATIENT_LOAD_REQUEST_DELTA_FETCH_ERROR',

  MEDICALRECORD_PATIENT_GET_LIST_REFERRAL_MODEL_FETCH_REQUEST    : 'MEDICALRECORD_PATIENT_GET_LIST_REFERRAL_MODEL_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_LIST_REFERRAL_MODEL_FETCH_SUCCESS    : 'MEDICALRECORD_PATIENT_GET_LIST_REFERRAL_MODEL_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_LIST_REFERRAL_MODEL_FETCH_ERROR      : 'MEDICALRECORD_PATIENT_GET_LIST_REFERRAL_MODEL_FETCH_ERROR',
  MEDICALRECORD_PATIENT_GET_REFERRAL_MODEL_BY_ID_FETCH_REQUEST   : 'MEDICALRECORD_PATIENT_GET_REFERRAL_MODEL_BY_ID_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_REFERRAL_MODEL_BY_ID_FETCH_SUCCESS   : 'MEDICALRECORD_PATIENT_GET_REFERRAL_MODEL_BY_ID_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_REFERRAL_MODEL_BY_ID_FETCH_ERROR     : 'MEDICALRECORD_PATIENT_GET_REFERRAL_MODEL_BY_ID_FETCH_ERROR',
  MEDICALRECORD_PATIENT_REMOVE_REFERRAL_FETCH_REQUEST            : 'MEDICALRECORD_PATIENT_REMOVE_REFERRAL_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_REMOVE_REFERRAL_FETCH_SUCCESS            : 'MEDICALRECORD_PATIENT_REMOVE_REFERRAL_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_REMOVE_REFERRAL_FETCH_ERROR              : 'MEDICALRECORD_PATIENT_REMOVE_REFERRAL_FETCH_ERROR',
  MEDICALRECORD_PATIENT_SAVE_REFERRAL_FETCH_REQUEST              : 'MEDICALRECORD_PATIENT_SAVE_REFERRAL_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_SAVE_REFERRAL_FETCH_SUCCESS              : 'MEDICALRECORD_PATIENT_SAVE_REFERRAL_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_SAVE_REFERRAL_FETCH_ERROR                : 'MEDICALRECORD_PATIENT_SAVE_REFERRAL_FETCH_ERROR',
  MEDICALRECORD_PATIENT_SET_REFERRAL_TEXTAREA                    : 'MEDICALRECORD_PATIENT_SET_REFERRAL_TEXTAREA',
  MEDICALRECORD_PATIENT_SET_REFERRAL_NEW_DESCRIPTION_CONTENT     : 'MEDICALRECORD_PATIENT_SET_REFERRAL_NEW_DESCRIPTION_CONTENT',
  MEDICALRECORD_PATIENT_LOAD_REFERRAL_DELTA_FETCH_REQUEST        : 'MEDICALRECORD_PATIENT_LOAD_REFERRAL_DELTA_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LOAD_REFERRAL_DELTA_FETCH_SUCCESS        : 'MEDICALRECORD_PATIENT_LOAD_REFERRAL_DELTA_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_LOAD_REFERRAL_DELTA_FETCH_ERROR          : 'MEDICALRECORD_PATIENT_LOAD_REFERRAL_DELTA_FETCH_ERROR',

  MEDICALRECORD_PATIENT_LOAD_VIDEO_URL_FETCH_REQUEST : 'MEDICALRECORD_PATIENT_LOAD_VIDEO_URL_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LOAD_VIDEO_URL_FETCH_SUCCESS : 'MEDICALRECORD_PATIENT_LOAD_VIDEO_URL_FETCH_SUCCESS',

  MEDICALRECORD_PATIENT_CREATE_FORM_ANSWER_AND_SECTION_FETCH_REQUEST  : 'MEDICALRECORD_PATIENT_CREATE_FORM_ANSWER_AND_SECTION_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_CREATE_FORM_ANSWER_AND_SECTION_FETCH_SUCCESS  : 'MEDICALRECORD_PATIENT_CREATE_FORM_ANSWER_AND_SECTION_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_CREATE_FORM_ANSWER_AND_SECTION_FETCH_ERROR    : 'MEDICALRECORD_PATIENT_CREATE_FORM_ANSWER_AND_SECTION_FETCH_ERROR',

  MEDICALRECORD_PATIENT_SAVE_SECTION_FETCH_REQUEST  : 'MEDICALRECORD_PATIENT_SAVE_SECTION_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_SAVE_SECTION_FETCH_SUCCESS  : 'MEDICALRECORD_PATIENT_SAVE_SECTION_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_SAVE_SECTION_FETCH_ERROR    : 'MEDICALRECORD_PATIENT_SAVE_SECTION_FETCH_ERROR',

  MEDICALRECORD_PATIENT_SAVE_MODAL_ATTENDANCE_RELEASE_FETCH_REQUEST : 'MEDICALRECORD_PATIENT_SAVE_MODAL_ATTENDANCE_RELEASE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_SAVE_MODAL_ATTENDANCE_RELEASE_FETCH_SUCCESS : 'MEDICALRECORD_PATIENT_SAVE_MODAL_ATTENDANCE_RELEASE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_SAVE_MODAL_ATTENDANCE_RELEASE_FETCH_ERROR   : 'MEDICALRECORD_PATIENT_SAVE_MODAL_ATTENDANCE_RELEASE_FETCH_ERROR',

  MEDICALRECORD_PATIENT_GET_BY_ID_FETCH_REQUEST : 'MEDICALRECORD_PATIENT_GET_BY_ID_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_BY_ID_FETCH_SUCCESS : 'MEDICALRECORD_PATIENT_GET_BY_ID_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_BY_ID_FETCH_ERROR   : 'MEDICALRECORD_PATIENT_GET_BY_ID_FETCH_ERROR',

  MEDICALRECORD_PATIENT_GET_ATTENDANCES_BY_PATIENT_ID_FETCH_REQUEST : 'MEDICALRECORD_PATIENT_GET_ATTENDANCES_BY_PATIENT_ID_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_ATTENDANCES_BY_PATIENT_ID_FETCH_SUCCESS : 'MEDICALRECORD_PATIENT_GET_ATTENDANCES_BY_PATIENT_ID_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_ATTENDANCES_BY_PATIENT_ID_FETCH_ERROR   : 'MEDICALRECORD_PATIENT_GET_ATTENDANCES_BY_PATIENT_ID_FETCH_ERROR',

  MEDICALRECORD_PATIENT_GET_SECTIONS_MEDICAL_RECORDS_CONFIG_FETCH_REQUEST: 'MEDICALRECORD_PATIENT_GET_SECTIONS_MEDICAL_RECORDS_CONFIG_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_SECTIONS_MEDICAL_RECORDS_CONFIG_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_GET_SECTIONS_MEDICAL_RECORDS_CONFIG_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_SECTIONS_MEDICAL_RECORDS_CONFIG_FETCH_ERROR: 'MEDICALRECORD_PATIENT_GET_SECTIONS_MEDICAL_RECORDS_CONFIG_FETCH_ERROR',

  MEDICALRECORD_PATIENT_CREATE_ATTENDANCE_ANSWER_PATIENT_FETCH_REQUEST: 'MEDICALRECORD_PATIENT_CREATE_ATTENDANCE_ANSWER_PATIENT_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_CREATE_ATTENDANCE_ANSWER_PATIENT_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_CREATE_ATTENDANCE_ANSWER_PATIENT_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_CREATE_ATTENDANCE_ANSWER_PATIENT_FETCH_ERROR: 'MEDICALRECORD_PATIENT_CREATE_ATTENDANCE_ANSWER_PATIENT_FETCH_ERROR',

  MEDICALRECORD_PATIENT_GET_ATTENDANCE_ANSWER_PATIENT_FETCH_REQUEST: 'MEDICALRECORD_PATIENT_GET_ATTENDANCE_ANSWER_PATIENT_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_ATTENDANCE_ANSWER_PATIENT_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_GET_ATTENDANCE_ANSWER_PATIENT_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_ATTENDANCE_ANSWER_PATIENT_FETCH_ERROR: 'MEDICALRECORD_PATIENT_GET_ATTENDANCE_ANSWER_PATIENT_FETCH_ERROR',


  MEDICALRECORD_PATIENT_SET_ROW_CONTENT : 'MEDICALRECORD_PATIENT_SET_ROW_CONTENT',

  MEDICALRECORD_PATIENT_SET_MEDIA_FILES : 'MEDICALRECORD_PATIENT_SET_MEDIA_FILES',
  MEDICALRECORD_PATIENT_SET_MEDIA_FILES_BY_ID : 'MEDICALRECORD_PATIENT_SET_MEDIA_FILES_BY_ID',

  MEDICALRECORD_PATIENT_SET_MEDIA_CHANGE_BY_ID : 'MEDICALRECORD_PATIENT_SET_MEDIA_CHANGE_BY_ID',
  MEDICALRECORD_PATIENT_SET_MEDIA_CHANGE_CATEGORIES_BY_ID: 'MEDICALRECORD_PATIENT_SET_MEDIA_CHANGE_CATEGORIES_BY_ID',

  MEDICALRECORD_PATIENT_SET_ATTACHMENT_FILES : 'MEDICALRECORD_PATIENT_SET_ATTACHMENT_FILES',
  MEDICALRECORD_PATIENT_SET_ATTACHMENT_FILES_BY_ID : 'MEDICALRECORD_PATIENT_SET_ATTACHMENT_FILES_BY_ID',

  MEDICALRECORD_PATIENT_EXAM_FETCH_REQUEST     : 'MEDICALRECORD_PATIENT_EXAM_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_EXAM_FETCH_SUCCESS     : 'MEDICALRECORD_PATIENT_EXAM_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_EXAM_FETCH_ERROR       : 'MEDICALRECORD_PATIENT_EXAM_FETCH_ERROR',
  MEDICALRECORD_PATIENT_SET_SEARCH_EXAM_RESET  : 'MEDICALRECORD_PATIENT_SET_SEARCH_EXAM_RESET',

  MEDICALRECORD_PATIENT_ADD_EXAM_DYNAMIC_FETCH_REQUEST  : 'MEDICALRECORD_PATIENT_ADD_EXAM_DYNAMIC_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_ADD_EXAM_DYNAMIC_FETCH_SUCCESS  : 'MEDICALRECORD_PATIENT_ADD_EXAM_DYNAMIC_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_ADD_EXAM_DYNAMIC_FETCH_ERROR    : 'MEDICALRECORD_PATIENT_ADD_EXAM_DYNAMIC_FETCH_ERROR',

  MEDICALRECORD_PATIENT_SAVE_EXAM_FETCH_REQUEST  : 'MEDICALRECORD_PATIENT_SAVE_EXAM_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_SAVE_EXAM_FETCH_SUCCESS  : 'MEDICALRECORD_PATIENT_SAVE_EXAM_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_SAVE_EXAM_FETCH_ERROR    : 'MEDICALRECORD_PATIENT_SAVE_EXAM_FETCH_ERROR',

  MEDICALRECORD_PATIENT_SET_UPDATE_EXAMS : 'MEDICALRECORD_PATIENT_SET_UPDATE_EXAMS',

  MEDICALRECORD_PATIENT_SET_UPDATE_EXAM_FIELD_BY_ID : 'MEDICALRECORD_PATIENT_SET_UPDATE_EXAM_FIELD_BY_ID',
  MEDICALRECORD_PATIENT_SET_UPDATE_EXAM_DATE_FIELD_BY_ID : 'MEDICALRECORD_PATIENT_SET_UPDATE_EXAM_DATE_FIELD_BY_ID',

  MEDICALRECORD_PATIENT_SET_ENABLE_HOUR_TABLE : 'MEDICALRECORD_PATIENT_SET_ENABLE_HOUR_TABLE',

  MEDICALRECORD_PATIENT_SET_DROP_MODEL_FORM: 'MEDICALRECORD_PATIENT_SET_DROP_MODEL_FORM',

  MEDICALRECORD_PATIENT_SET_STATUS_BAR: 'MEDICALRECORD_PATIENT_SET_STATUS_BAR',

  MEDICALRECORD_PATIENT_CHANGE_COMMENTS_PRESCRIPTION  : 'MEDICALRECORD_PATIENT_CHANGE_COMMENTS_PRESCRIPTION',
  MEDICALRECORD_PATIENT_SET_COMMENTS_PRESCRIPTION     : 'MEDICALRECORD_PATIENT_SET_COMMENTS_PRESCRIPTION',
  
  MEDICALRECORD_PATIENT_CHANGE_COMMENTS_CERTIFICATE  : 'MEDICALRECORD_PATIENT_CHANGE_COMMENTS_CERTIFICATE',
  MEDICALRECORD_PATIENT_SET_COMMENTS_CERTIFICATE     : 'MEDICALRECORD_PATIENT_SET_COMMENTS_CERTIFICATE',

  MEDICALRECORD_PATIENT_CHANGE_COMMENTS_REFERRAL  : 'MEDICALRECORD_PATIENT_CHANGE_COMMENTS_REFERRAL',
  MEDICALRECORD_PATIENT_SET_COMMENTS_REFERRAL     : 'MEDICALRECORD_PATIENT_SET_COMMENTS_REFERRAL',

  MEDICALRECORD_PATIENT_CHANGE_COMMENTS_REQUEST  : 'MEDICALRECORD_PATIENT_CHANGE_COMMENTS_REQUEST',
  MEDICALRECORD_PATIENT_SET_COMMENTS_REQUEST     : 'MEDICALRECORD_PATIENT_SET_COMMENTS_REQUEST',

  MEDICALRECORD_PATIENT_CHANGE_COMMENTS_REPORT  : 'MEDICALRECORD_PATIENT_CHANGE_COMMENTS_REPORT',
  MEDICALRECORD_PATIENT_SET_COMMENTS_REPORT     : 'MEDICALRECORD_PATIENT_SET_COMMENTS_REPORT',

  MEDICALRECORD_PATIENT_CHANGE_COMMENTS_SECTION_BY_KEY : 'MEDICALRECORD_PATIENT_CHANGE_COMMENTS_SECTION_BY_KEY',
  MEDICALRECORD_PATIENT_SET_COMMENTS_SECTION_BY_KEY : 'MEDICALRECORD_PATIENT_SET_COMMENTS_SECTION_BY_KEY',

  MEDICALRECORD_PATIENT_TOGGLE_MEDIA_LIGHT_BOX : 'MEDICALRECORD_PATIENT_TOGGLE_MEDIA_LIGHT_BOX',
  MEDICALRECORD_PATIENT_SET_MEDIA_LIGHT_BOX_INDEX : 'MEDICALRECORD_PATIENT_SET_MEDIA_LIGHT_BOX_INDEX',

  MEDICALRECORD_PATIENT_SET_MEDIA_VIDEO_BOX : 'MEDICALRECORD_PATIENT_SET_MEDIA_VIDEO_BOX',

  MEDICALRECORD_PATIENT_GET_HISTORY_EXAM_FETCH_REQUEST    : 'MEDICALRECORD_PATIENT_GET_HISTORY_EXAM_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_HISTORY_EXAM_FETCH_SUCCESS    : 'MEDICALRECORD_PATIENT_GET_HISTORY_EXAM_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_HISTORY_EXAM_FETCH_ERROR      : 'MEDICALRECORD_PATIENT_GET_HISTORY_EXAM_FETCH_ERROR',

  MEDICALRECORD_PATIENT_GET_FORM_TO_PRINT_FETCH_REQUEST   : 'MEDICALRECORD_PATIENT_GET_FORM_TO_PRINT_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_FORM_TO_PRINT_FETCH_SUCCESS   : 'MEDICALRECORD_PATIENT_GET_FORM_TO_PRINT_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_FORM_TO_PRINT_FETCH_ERROR     : 'MEDICALRECORD_PATIENT_GET_FORM_TO_PRINT_FETCH_ERROR',

  MEDICALRECORD_PATIENT_BUILD_ATTENDANCE_BY_ID : 'MEDICALRECORD_PATIENT_BUILD_ATTENDANCE_BY_ID',

  MEDICALRECORD_PATIENT_SET_SUMMARY_ATTENDANCE_BY_TABLE : 'MEDICALRECORD_PATIENT_SET_SUMMARY_ATTENDANCE_BY_TABLE',

  MEDICALRECORD_PATIENT_GET_EXAM_BY_PATIENT_ID_FETCH_REQUEST  : 'MEDICALRECORD_PATIENT_GET_EXAM_BY_PATIENT_ID_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_EXAM_BY_PATIENT_ID_FETCH_SUCCESS  : 'MEDICALRECORD_PATIENT_GET_EXAM_BY_PATIENT_ID_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_EXAM_BY_PATIENT_ID_FETCH_ERROR    : 'MEDICALRECORD_PATIENT_GET_EXAM_BY_PATIENT_ID_FETCH_ERROR',

  MEDICALRECORD_PATIENT_GET_ALBUM_PHOTO_FETCH_REQUEST      : 'MEDICALRECORD_PATIENT_GET_ALBUM_PHOTO_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_ALBUM_PHOTO_FETCH_SUCCESS      : 'MEDICALRECORD_PATIENT_GET_ALBUM_PHOTO_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_ALBUM_PHOTO_FORMS_IS_FILTER_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_GET_ALBUM_PHOTO_FORMS_IS_FILTER_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_ALBUM_PHOTO_FORMS_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_GET_ALBUM_PHOTO_FORMS_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_ALBUM_PHOTO_IS_FILTER_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_GET_ALBUM_PHOTO_IS_FILTER_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_ALBUM_PHOTO_FETCH_ERROR        : 'MEDICALRECORD_PATIENT_GET_ALBUM_PHOTO_FETCH_ERROR',
  MEDICALRECORD_PATIENT_RESET_ALBUM_PHOTO                  : 'MEDICALRECORD_PATIENT_RESET_ALBUM_PHOTO',
  MEDICALRECORD_PATIENT_SET_ALBUM_PHOTO_LIST_SOURCE_BY_ID  : 'MEDICALRECORD_PATIENT_SET_ALBUM_PHOTO_LIST_SOURCE_BY_ID',
  MEDICALRECORD_PATIENT_SET_ALBUM_PHOTO_SELECTED           : 'MEDICALRECORD_PATIENT_SET_ALBUM_PHOTO_SELECTED',
  MEDICALRECORD_PATIENT_SET_ALBUM_PHOTO_FORMS_SELECTED     : 'MEDICALRECORD_PATIENT_SET_ALBUM_PHOTO_FORMS_SELECTED',
  MEDICALRECORD_PATIENT_SET_TOGGLE_ALBUM_PHOTO_LIGHTBOX    : 'MEDICALRECORD_PATIENT_SET_TOGGLE_ALBUM_PHOTO_LIGHTBOX',
  MEDICALRECORD_PATIENT_SET_TOGGLE_ALBUM_PHOTO_FORMS_LIGHTBOX: 'MEDICALRECORD_PATIENT_SET_TOGGLE_ALBUM_PHOTO_FORMS_LIGHTBOX',
  MEDICALRECORD_PATIENT_SET_ALBUM_PHOTO_FORMS_LIST_SOURCE_BY_ID: 'MEDICALRECORD_PATIENT_SET_ALBUM_PHOTO_FORMS_LIST_SOURCE_BY_ID',
  MEDICALRECORD_PATIENT_RESET_ALBUM_PHOTO_FORMS: 'MEDICALRECORD_PATIENT_RESET_ALBUM_PHOTO_FORMS',

  MEDICALRECORD_PATIENT_SET_UPLOAD_FILES_BY_ID : 'MEDICALRECORD_PATIENT_SET_UPLOAD_FILES_BY_ID',
  MEDICALRECORD_PATIENT_SET_REMOVED_FILES_BY_ID : 'MEDICALRECORD_PATIENT_SET_REMOVED_FILES_BY_ID',
  MEDICALRECORD_PATIENT_ATTENDANCE_SET_SHARE_CONFIG : 'MEDICALRECORD_PATIENT_ATTENDANCE_SET_SHARE_CONFIG',
  MEDICALRECORD_PATIENT_ATTENDANCE_SHARE_CONFIG_LOAD_USER_ACCESS_GROUPS_FETCH_REQUEST : 'MEDICALRECORD_PATIENT_ATTENDANCE_SHARE_CONFIG_LOAD_USER_ACCESS_GROUPS_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_ATTENDANCE_SHARE_CONFIG_LOAD_USER_ACCESS_GROUPS_FETCH_SUCCESS : 'MEDICALRECORD_PATIENT_ATTENDANCE_SHARE_CONFIG_LOAD_USER_ACCESS_GROUPS_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_ATTENDANCE_SHARE_CONFIG_LOAD_USER_ACCESS_GROUPS_FETCH_ERROR   : 'MEDICALRECORD_PATIENT_ATTENDANCE_SHARE_CONFIG_LOAD_USER_ACCESS_GROUPS_FETCH_ERROR',
  MEDICALRECORD_PATIENT_ATTENDANCE_SAVE_SHARE_CONFIG_FETCH_REQUEST : 'MEDICALRECORD_PATIENT_ATTENDANCE_SAVE_SHARE_CONFIG_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_ATTENDANCE_SAVE_SHARE_CONFIG_FETCH_SUCCESS : 'MEDICALRECORD_PATIENT_ATTENDANCE_SAVE_SHARE_CONFIG_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_ATTENDANCE_SAVE_SHARE_CONFIG_FETCH_ERROR   : 'MEDICALRECORD_PATIENT_ATTENDANCE_SAVE_SHARE_CONFIG_FETCH_ERROR',
  MEDICALRECORD_PATIENT_ATTENDANCE_DELETE_FETCH_REQUEST    : 'MEDICALRECORD_PATIENT_ATTENDANCE_DELETE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_ATTENDANCE_DELETE_FETCH_SUCCESS    : 'MEDICALRECORD_PATIENT_ATTENDANCE_DELETE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_ATTENDANCE_DELETE_FETCH_ERROR      : 'MEDICALRECORD_PATIENT_ATTENDANCE_DELETE_FETCH_ERROR',
  MEDICALRECORD_PATIENT_SET_TOGGLE_ALBUM_VIDEO_LIGHTBOX    : 'MEDICALRECORD_PATIENT_SET_TOGGLE_ALBUM_VIDEO_LIGHTBOX',
  MEDICALRECORD_PATIENT_SET_ALBUM_VIDEO_SELECTED           : 'MEDICALRECORD_PATIENT_SET_ALBUM_VIDEO_SELECTED',
  MEDICALRECORD_PATIENT_SET_ALBUM_VIDEO_LIST_SOURCE_BY_ID  : 'MEDICALRECORD_PATIENT_SET_ALBUM_VIDEO_LIST_SOURCE_BY_ID',
  MEDICALRECORD_PATIENT_GET_ALBUM_VIDEO_FETCH_ERROR        : 'MEDICALRECORD_PATIENT_GET_ALBUM_VIDEO_FETCH_ERROR',
  MEDICALRECORD_PATIENT_RESET_ALBUM_VIDEO                  : 'MEDICALRECORD_PATIENT_RESET_ALBUM_VIDEO',
  MEDICALRECORD_PATIENT_GET_ALBUM_VIDEO_FETCH_SUCCESS      : 'MEDICALRECORD_PATIENT_GET_ALBUM_VIDEO_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_ALBUM_VIDEO_FETCH_REQUEST      : 'MEDICALRECORD_PATIENT_GET_ALBUM_VIDEO_FETCH_REQUEST',

  MEDICALRECORD_PATIENT_SET_NOT_RELEASED_ATTENDANCES_PROFESSIONALS : 'MEDICALRECORD_PATIENT_SET_NOT_RELEASED_ATTENDANCES_PROFESSIONALS',

  MEDICALRECORD_PATIENT_GET_PROFESSIONAL_BY_USER_ID_FETCH_REQUEST: 'MEDICALRECORD_PATIENT_GET_PROFESSIONAL_BY_USER_ID_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_PROFESSIONAL_BY_USER_ID_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_GET_PROFESSIONAL_BY_USER_ID_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_PROFESSIONAL_BY_USER_ID_FETCH_ERROR: 'MEDICALRECORD_PATIENT_GET_PROFESSIONAL_BY_USER_ID_FETCH_ERROR',
  MEDICALRECORD_PATIENT_SET_FORM_MODELS: 'MEDICALRECORD_PATIENT_SET_FORM_MODELS',
  MEDICALRECORD_PATIENT_SET_FORM_SEND_CREATED: 'MEDICALRECORD_PATIENT_SET_FORM_SEND_CREATED',

  MODAL_CORE_LINE_SELECT: 'MODAL_CORE_LINE_SELECT',
  MEDICALRECORD_PATIENT_GET_LIST_CORE_LINES_FORM: 'MEDICALRECORD_PATIENT_GET_LIST_CORE_LINES_FORM',
  MEDICALRECORD_PATIENT_SET_LIST_CORE_LINES_FORM_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_SET_LIST_CORE_LINES_FORM_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_LIST_CORE_LINES_FORM_ERROR: 'MEDICALRECORD_PATIENT_GET_LIST_CORE_LINES_FORM_ERROR',
  MEDICALRECORD_PATIENT_OPEN_CORE_LINE_FETCH_REQUEST: 'MEDICALRECORD_PATIENT_OPEN_CORE_LINE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_OPEN_CORE_LINE_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_OPEN_CORE_LINE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_OPEN_CORE_LINE_FETCH_ERROR: 'MEDICALRECORD_PATIENT_OPEN_CORE_LINE_FETCH_ERROR',
  MEDICALRECORD_PATIENT_GET_OPEN_CORE_LINE_FETCH_REQUEST: 'MEDICALRECORD_PATIENT_GET_OPEN_CORE_LINE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_OPEN_CORE_LINE_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_GET_OPEN_CORE_LINE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_OPEN_CORE_LINE_FETCH_ERROR: 'MEDICALRECORD_PATIENT_GET_OPEN_CORE_LINE_FETCH_ERROR',
  MEDICALRECORD_PATIENT_GET_CORE_LINES_BY_PATIENT_ID_FORM_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_GET_CORE_LINES_BY_PATIENT_ID_FORM_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_CORE_LINES_BY_PATIENT_ID_FORM_FETCH_REQUEST: 'MEDICALRECORD_PATIENT_GET_CORE_LINES_BY_PATIENT_ID_FORM_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_CORE_LINES_BY_PATIENT_ID_FORM_FETCH_ERROR: 'MEDICALRECORD_PATIENT_GET_CORE_LINES_BY_PATIENT_ID_FORM_FETCH_ERROR',
  MEDICALRECORD_PATIENT_UPDATE_OPEN_CORE_LINE_FETCH_REQUEST: 'MEDICALRECORD_PATIENT_UPDATE_OPEN_CORE_LINE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_UPDATE_OPEN_CORE_LINE_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_UPDATE_OPEN_CORE_LINE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_UPDATE_OPEN_CORE_LINE_FETCH_ERROR: 'MEDICALRECORD_PATIENT_UPDATE_OPEN_CORE_LINE_FETCH_ERROR',
  MEDICALRECORD_PATIENT_UPDATE_ATTENDANCE_CORE_LINE_FETCH_REQUEST: 'MEDICALRECORD_PATIENT_UPDATE_ATTENDANCE_CORE_LINE_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_UPDATE_ATTENDANCE_CORE_LINE_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_UPDATE_ATTENDANCE_CORE_LINE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_UPDATE_ATTENDANCE_CORE_LINE_FETCH_ERROR: 'MEDICALRECORD_PATIENT_UPDATE_ATTENDANCE_CORE_LINE_FETCH_ERROR',
  MEDICALRECORD_PATIENT_SET_MODAL_PRINT_ATTENDANCE: 'MEDICALRECORD_PATIENT_SET_MODAL_PRINT_ATTENDANCE',

  MEDICALRECORD_PATIENT_SET_FETCH_LOADING_GENERATE_PDF: 'MEDICALRECORD_PATIENT_SET_FETCH_LOADING_GENERATE_PDF',

  MODAL_CONFIG_GRAPHIC: 'MODAL_CONFIG_GRAPHIC',

  MEDICALRECORD_PATIENT_FETCH_SEARCH_FIELDS_MEDICAL_RECORDS_REQUEST: 'MEDICALRECORD_PATIENT_FETCH_SEARCH_FIELDS_MEDICAL_RECORDS_REQUEST',
  MEDICALRECORD_PATIENT_FETCH_SEARCH_FIELDS_MEDICAL_RECORDS_SUCCESS: 'MEDICALRECORD_PATIENT_FETCH_SEARCH_FIELDS_MEDICAL_RECORDS_SUCCESS',
  MEDICALRECORD_PATIENT_FETCH_SEARCH_FIELDS_MEDICAL_RECORDS_ERROR: 'MEDICALRECORD_PATIENT_FETCH_SEARCH_FIELDS_MEDICAL_RECORDS_ERROR',

  MEDICALRECORD_PATIENT_FETCH_GET_FIELD_MODEL_VERSION_BY_ID_REQUEST: 'MEDICALRECORD_PATIENT_FETCH_GET_FIELD_MODEL_VERSION_BY_ID_REQUEST',
  MEDICALRECORD_PATIENT_FETCH_GET_FIELD_MODEL_VERSION_BY_ID_SUCCESS: 'MEDICALRECORD_PATIENT_FETCH_GET_FIELD_MODEL_VERSION_BY_ID_SUCCESS',
  MEDICALRECORD_PATIENT_FETCH_GET_FIELD_MODEL_VERSION_BY_ID_ERROR: 'MEDICALRECORD_PATIENT_FETCH_GET_FIELD_MODEL_VERSION_BY_ID_ERROR',

  MEDICALRECORD_PATIENT_CREATE_INDICATORS_MEDICAL_RECORDS_FETCH_REQUEST: 'MEDICALRECORD_PATIENT_CREATE_INDICATORS_MEDICAL_RECORDS_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_CREATE_INDICATORS_MEDICAL_RECORDS_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_CREATE_INDICATORS_MEDICAL_RECORDS_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_CREATE_INDICATORS_MEDICAL_RECORDS_FETCH_ERROR: 'MEDICALRECORD_PATIENT_CREATE_INDICATORS_MEDICAL_RECORDS_FETCH_ERROR',

  MEDICALRECORD_PATIENT_GET_INDICATORS_MEDICAL_RECORDS_FETCH_REQUEST: 'MEDICALRECORD_PATIENT_GET_INDICATORS_MEDICAL_RECORDS_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_GET_INDICATORS_MEDICAL_RECORDS_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_GET_INDICATORS_MEDICAL_RECORDS_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_GET_INDICATORS_MEDICAL_RECORDS_FETCH_ERROR: 'MEDICALRECORD_PATIENT_GET_INDICATORS_MEDICAL_RECORDS_FETCH_ERROR',

  MEDICALRECORD_PATIENT_UPDATE_INDICATORS_MEDICAL_RECORDS_FETCH_REQUEST: 'MEDICALRECORD_PATIENT_UPDATE_INDICATORS_MEDICAL_RECORDS_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_UPDATE_INDICATORS_MEDICAL_RECORDS_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_UPDATE_INDICATORS_MEDICAL_RECORDS_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_UPDATE_INDICATORS_MEDICAL_RECORDS_FETCH_ERROR: 'MEDICALRECORD_PATIENT_UPDATE_INDICATORS_MEDICAL_RECORDS_FETCH_ERROR',

  MODAL_DASHBOARD_MEDICAL_RECORDS: 'MODAL_DASHBOARD_MEDICAL_RECORDS',

  MEDICALRECORD_PATIENT_LIST_INDICATORS_MEDICAL_RECORDS_FETCH_REQUEST: 'MEDICALRECORD_PATIENT_LIST_INDICATORS_MEDICAL_RECORDS_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LIST_INDICATORS_MEDICAL_RECORDS_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_LIST_INDICATORS_MEDICAL_RECORDS_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_LIST_INDICATORS_MEDICAL_RECORDS_FETCH_ERROR: 'MEDICALRECORD_PATIENT_LIST_INDICATORS_MEDICAL_RECORDS_FETCH_ERROR',

  MEDICALRECORD_PATIENT_SET_DATA_HISTORY_BY_FIELD_MODEL_ID: 'MEDICALRECORD_PATIENT_SET_DATA_HISTORY_BY_FIELD_MODEL_ID',
  MEDICALRECORD_PATIENT_SET_DATA_FIELD_MODEL_ID: 'MEDICALRECORD_PATIENT_SET_DATA_FIELD_MODEL_ID',
  MEDICALRECORD_PATIENT_GET_DATA_HISTORY_BY_FIELD_MODEL_ID_REQUEST: 'MEDICALRECORD_PATIENT_GET_DATA_HISTORY_BY_FIELD_MODEL_ID_REQUEST',
  MEDICALRECORD_PATIENT_GET_DATA_HISTORY_BY_FIELD_MODEL_ID_ERROR: 'MEDICALRECORD_PATIENT_GET_DATA_HISTORY_BY_FIELD_MODEL_ID_ERROR',
  
  MEDICALRECORD_PATIENT_GET_IMAGE_GRAPHIC_REQUEST: 'MEDICALRECORD_PATIENT_GET_IMAGE_GRAPHIC_REQUEST',
  MEDICALRECORD_PATIENT_SET_URL_IMAGE_GRAPHIC: 'MEDICALRECORD_PATIENT_SET_URL_IMAGE_GRAPHIC',
  MEDICALRECORD_PATIENT_GET_IMAGE_GRAPHIC_ERROR: 'MEDICALRECORD_PATIENT_GET_IMAGE_GRAPHIC_ERROR',

  MEDICALRECORD_PATIENT_DELETE_INDICATORS_MEDICAL_RECORDS_FETCH_REQUEST: 'MEDICALRECORD_PATIENT_DELETE_INDICATORS_MEDICAL_RECORDS_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_DELETE_INDICATORS_MEDICAL_RECORDS_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_DELETE_INDICATORS_MEDICAL_RECORDS_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_DELETE_INDICATORS_MEDICAL_RECORDS_FETCH_ERROR: 'MEDICALRECORD_PATIENT_DELETE_INDICATORS_MEDICAL_RECORDS_FETCH_ERROR',

  MEDICALRECORD_PATIENT_LIST_INDICATORS_DASHBOARD_MODELS_MEDICAL_RECORDS_FETCH_REQUEST: 'MEDICALRECORD_PATIENT_LIST_INDICATORS_DASHBOARD_MODELS_MEDICAL_RECORDS_FETCH_REQUEST',
  MEDICALRECORD_PATIENT_LIST_INDICATORS_DASHBOARD_MODELS_MEDICAL_RECORDS_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_LIST_INDICATORS_DASHBOARD_MODELS_MEDICAL_RECORDS_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_LIST_INDICATORS_DASHBOARD_MODELS_MEDICAL_RECORDS_FETCH_ERROR: 'MEDICALRECORD_PATIENT_LIST_INDICATORS_DASHBOARD_MODELS_MEDICAL_RECORDS_FETCH_ERROR',

  MEDICALRECORD_PATIENT_SET_LIST_CATEGORIES_FILES_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_SET_LIST_CATEGORIES_FILES_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_SET_LIST_CATEGORIES_FILES_ALBUM_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_SET_LIST_CATEGORIES_FILES_ALBUM_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_SET_LIST_CATEGORIES_IMAGE_TIMELINE_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_SET_LIST_CATEGORIES_IMAGE_TIMELINE_FETCH_SUCCESS',
  MEDICALRECORD_PATIENT_SET_LIST_CATEGORIES_IMAGE_SECTION_TIMELINE: 'MEDICALRECORD_PATIENT_SET_LIST_CATEGORIES_IMAGE_SECTION_TIMELINE',
});
