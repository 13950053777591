import initialStateMedicalRecordOverview from './MedicalRecord/Overview/containers/initialState';
import * as commonReducerMedicalRecordOverview from './MedicalRecord/Overview/containers/reducerGenerator';

import initialStateMedicalRecordFields from './MedicalRecord/Fields/config/initialState';
import * as commonReducerMedicalRecordFields from './MedicalRecord/Fields/config/reducerGenerator';

import initialStateMedicalRecordExercises from './MedicalRecord/Exercises/config/initialState';
import * as commonReducerMedicalRecordExercises from './MedicalRecord/Exercises/config/reducerGenerator';

import initialStateConfigurationMedicalRecordForms from './MedicalRecord/Forms/config/initialState';
import * as commonReducerConfigurationMedicalRecordForms from './MedicalRecord/Forms/config/reducerGenerator';

import initialStateConfigurationMedicalRecordSections from './MedicalRecord/Sections/config/initialState';
import * as commonReducerConfigurationMedicalRecordSections from './MedicalRecord/Sections/config/reducerGenerator';

import initialStateConfigurationMedicalRecordQualifiers from './MedicalRecord/Qualifiers/config/initialState';
import * as commonReducerConfigurationMedicalRecordQualifiers from './MedicalRecord/Qualifiers/config/reducerGenerator';

import initialStateDrawer from './MedicalRecord/DrawerSections/config/initialState';
import * as commonReducerDrawer from './MedicalRecord/DrawerSections/config/reducerGenerator';

import initialStateMedicalRecordCoreLine from './MedicalRecord/CoreLine/config/initialState';
import * as commonReducerMedicalRecordCoreLine from './MedicalRecord/CoreLine/config/reducerGenerator';

import initialStateMedicalRecordModelDashboard from './MedicalRecord/DashboardModels/config/initialState';
import * as commonReducerMedicalRecordModelDashboard from './MedicalRecord/DashboardModels/config/reducerGenerator';

import * as commonReducerMedicalRecordCategoriesFiles from './MedicalRecord/CategoryImages/config/reducerGenerator';
import initialStateMedicalRecordCategoriesFiles from './MedicalRecord/CategoryImages/config/initialState';

const INITIAL_STATE = {
  medicalRecord: {
    ...initialStateMedicalRecordFields,
    ...initialStateMedicalRecordExercises,
    drawer: { ...initialStateDrawer },
    overview: { ...initialStateMedicalRecordOverview },
    forms: { ...initialStateConfigurationMedicalRecordForms },
    sections: { ...initialStateConfigurationMedicalRecordSections },
    qualifiers: { ...initialStateConfigurationMedicalRecordQualifiers },
    coreLines: { ...initialStateMedicalRecordCoreLine },
    modelDashboard: { ...initialStateMedicalRecordModelDashboard },
    categories: { ...initialStateMedicalRecordCategoriesFiles },
  },
};

export default (state = INITIAL_STATE, action) => {
  if (typeof commonReducerMedicalRecordOverview[action.type] === 'function') {
    return commonReducerMedicalRecordOverview[action.type](state, action);
  } else if (
    typeof commonReducerMedicalRecordFields[action.type] === 'function'
  ) {
    return commonReducerMedicalRecordFields[action.type](state, action);
  } else if (
    typeof commonReducerMedicalRecordExercises[action.type] === 'function'
  ) {
    return commonReducerMedicalRecordExercises[action.type](state, action);
  } else if (
    typeof commonReducerConfigurationMedicalRecordForms[action.type] ===
    'function'
  ) {
    return commonReducerConfigurationMedicalRecordForms[action.type](
      state,
      action
    );
  } else if (
    typeof commonReducerConfigurationMedicalRecordSections[action.type] ===
    'function'
  ) {
    return commonReducerConfigurationMedicalRecordSections[action.type](
      state,
      action
    );
  } else if (
    typeof commonReducerConfigurationMedicalRecordQualifiers[action.type] ===
    'function'
  ) {
    return commonReducerConfigurationMedicalRecordQualifiers[action.type](
      state,
      action
    );
  } else if (typeof commonReducerDrawer[action.type] === 'function') {
    return commonReducerDrawer[action.type](state, action);
  } else if (typeof commonReducerMedicalRecordCoreLine[action.type] === 'function') {
    return commonReducerMedicalRecordCoreLine[action.type](state, action);
  } else if (typeof commonReducerMedicalRecordModelDashboard[action.type] === 'function') {
    return commonReducerMedicalRecordModelDashboard[action.type](state, action);
  } else if (typeof commonReducerMedicalRecordCategoriesFiles[action.type] === 'function') {
    return commonReducerMedicalRecordCategoriesFiles[action.type](state, action);
  }
   else {
    return state;
  }
};
