export default {
  sections: {
    done: true,
    activeKey: 'tab:resumo',
    enableHour: false,
    targetState: '',
    attendanceId: 0,
    attendanceDate: null,
    getExamHistory: false,
    getAlbumPhoto: false,
    attendanceReleased: false,
    listSectionsModels: [],
    formModels: {},
    sectionsModels: {},
    sectionsModelsConfig: [],

    statusBar: {
      done: false,
      message: ''
    },

    sectionsDefaults: [
      { name: 'exam', title: 'Exames' },
      { name: 'media', title: 'Arquivos de Mídia' },
      { name: 'attachment', title: 'Anexos' },
      { name: 'prescription', title: 'Prescrição' },
      { name: 'certificate', title: 'Atestado' },
      { name: 'request', title: 'Solicitação' },
      { name: 'referral', title: 'Encaminhamento' },
      { name: 'report', title: 'Relatório' }
    ],

    exam: {
      name: 'Exames',

      loading: false,
      searchLoading: false,
      disabledSearch: false,
      addExamDynamic: false,
      saveExamFragmentField: false,

      saveExamFragmentMessage: null,

      listExam: [],
      listKeys: [],
      listSource: {},
      listFields: {},
      examHistory: []
    },

    media: {
      name: 'Arquivo de Mídia',
      photoIndex: 0,
      video: {
        loading: '',
        videoName: '',
        videoPath: ''
      },
      loading: false,
      isOpen: false,
      listKeys: [],
      listSource: {},
      listImages: [],
      uploadFiles: [],
      removedFiles: [],
      listCategories: [],
    },

    report: {
      name: 'Relatório',
      listModel: [],
      listKeys: [],
      listSource: {},
      comments: {
        value: null,
        loading: false,
        listKeys: [],
        listSource: {}
      }
    },

    request: {
      name: 'Solicitação',
      listModel: [],
      listKeys: [],
      listSource: {},
      comments: {
        value: null,
        loading: false,
        listKeys: [],
        listSource: {}
      }
    },

    referral: {
      name: 'Encaminhamento',
      listModel: [],
      listKeys: [],
      listSource: {},
      comments: {
        value: null,
        loading: false,
        listKeys: [],
        listSource: {}
      }
    },

    attachment: {
      name: 'Anexos',
      listKeys: [],
      listSource: {}
    },

    certificate: {
      name: 'Atestado',
      listModel: [],
      listKeys: [],
      listSource: {},
      comments: {
        value: null,
        loading: false,
        listKeys: [],
        listSource: {}
      }
    },

    prescription: {
      name: 'Prescrição',
      listKeys: [],
      listModel: [],
      listSource: {},
      comments: {
        value: null,
        loading: false,
        listKeys: [],
        listSource: {}
      }
    },

    album: {
      listCategories: [],
      photo: {
        selected: [],
        listKeys: [],
        openLight: false,
        listImages: [],
        listSource: {},
        lastLoadedPage: 0,
        reloadFetch: false,
        listKeysOriginal: [],
        olderIndexesLoaded: []
      },
      albumForms:{
        selected: [],
        listKeys: [],
        openLight: false,
        listImages: [],
        listSource: {},
        lastLoadedPage: 0,
        reloadFetch: false,
        listKeysOriginal: [],
        olderIndexesLoaded: []
      },
      video: {
        selected: [],
        listKeys: [],
        openLight: false,
        listSource: {},
        listVideos: [],
        lastLoadedPage: 0,
        reloadFetch: false,
        listKeysOriginal: [],
        olderIndexesLoaded: []
      }
    }
  },

  formsSend: [],
  formSendCreated: {
},

  listSections:[],
  listCoreLines:[],
  listCoreLinesOpen: [],
  listCoreLinesByPatientId: [],

  userInfo: {
    done: true,
    patient: {
      rg: '',
      age: '',
      cpf: '',
      name: '',
      email: '',
      contact: '',
      birthdate: '',
      profession: '',
      maritalStatus: 0  ,
      sex: 0,
      patientId: 0
    }
  },

  patientId: null,

  attendanceShareConfiguration: {
    medicalRecordsAttendanceId: null,
    userAccessGroups: {},
    isPublic: false,
    userIdCreated: null,
    medicalRecordsAccessGroupId: null,
    checkedShare: false
  },

  mainTimelineData: {
    attendances: [],
    medicalRecordsAccessGroups: [],
    examsModels: [],
    referencedUsers: [],
    lastLoadedPage: 0,
    currentSectionName: null,
    downloadedImages: {},
    requestedAttachments: {},
    videoUrl: null,
    timelineEnd: false,
    listCategories: [],
  },

  certificateTimelineData: {
    attendances: [],
    medicalRecordsAccessGroups: [],
    referencedUsers: [],
    lastDateLoaded: null,
    lastDateLoadedItems: [],
    lessRecentAttendanceDateLoaded: null,
    timelineEnd: false
  },

  prescriptionTimelineData: {
    attendances: [],
    medicalRecordsAccessGroups: [],
    referencedUsers: [],
    lastDateLoaded: null,
    lastDateLoadedItems: [],
    lessRecentAttendanceDateLoaded: null,
    timelineEnd: false
  },

  referralTimelineData: {
    attendances: [],
    medicalRecordsAccessGroups: [],
    referencedUsers: [],
    lastDateLoaded: null,
    lastDateLoadedItems: [],
    lessRecentAttendanceDateLoaded: null,
    timelineEnd: false
  },

  requestTimelineData: {
    attendances: [],
    medicalRecordsAccessGroups: [],
    referencedUsers: [],
    lastDateLoaded: null,
    lastDateLoadedItems: [],
    lessRecentAttendanceDateLoaded: null,
    timelineEnd: false
  },

  reportTimelineData: {
    attendances: [],
    medicalRecordsAccessGroups: [],
    referencedUsers: [],
    lastDateLoaded: null,
    lastDateLoadedItems: [],
    lessRecentAttendanceDateLoaded: null,
    timelineEnd: false
  },

  mediaTimelineData: {
    attendances: [],
    medicalRecordsAccessGroups: [],
    referencedUsers: [],
    lastDateLoaded: null,
    lastDateLoadedItems: [],
    lessRecentAttendanceDateLoaded: null,
    downloadedImages: {},
    imagesUrls: {},
    videoUrl: null,
    timelineEnd: false
  },

  attachmentTimelineData: {
    attendances: [],
    medicalRecordsAccessGroups: [],
    referencedUsers: [],
    lastDateLoaded: null,
    lastDateLoadedItems: [],
    lessRecentAttendanceDateLoaded: null,
    requestedAttachments: {},
    timelineEnd: false
  },

  sectionTimelineData: {
    // attendances: [],
    // medicalRecordsAccessGroups: [],
    // referencedUsers: [],
    // lastDateLoaded: null,
    // lastDateLoadedItems: [],
    // lessRecentAttendanceDateLoaded: null,
    // timelineEnd: false
  },

  examTimelineData: {
    attendances: [],
    medicalRecordsAccessGroups: [],
    referencedUsers: [],
    lastDateLoaded: null,
    lastDateLoadedItems: [],
    lessRecentAttendanceDateLoaded: null,
    timelineEnd: false
  },

  summaryAttendance: {
    table: [],
    tableSmallerDate: [],

    userIds: [],
    users: {},

    attendancesIds: [],
    attendances: {},

    reloadFetch: true,
    lastLoaded: '',
    olderIndexesLoaded: []
  },

  fetching: {
    getSectionsMedicalRecordsConfig: false,
    createAttendanceAnswerPatient: false,
    getAttendanceAnswerPatient: false,
    getAttendanceById: false,
    getListPrescriptionModel: false,
    getPrescriptionModelById: false,
    getListCertificateModel: false,
    getCertificateModelById: false,
    getTimeline: false,
    getCertificateTimeline: false,
    getPrescriptionTimeline: false,
    getRequestTimeline: false,
    getReferralTimeline: false,
    getReportTimeline: false,
    getMediaTimeline: false,
    getAttachmentTimeline: false,
    getExamTimeline: false,
    getSectionTimeline: false,
    getListReportModel: false,
    getReportModelById: false,
    getListRequestModel: false,
    getRequestModelById: false,
    getListReferralModel: false,
    getReferralModelById: false,
    getPatientById: true,
    getAttendancesByPatientId: false,
    loadUserAccessGroups: false,
    saveMedicalRecordsAttendanceShareConfig: false,
    deleteMedicalRecordsAttendance: false,
    prescriptionDelta: false,
    certificateDelta: false,
    referralDelta: false,
    requestDelta: false,
    reportDelta: false,
    formToPrint: false,

    postAttendance: false,
    saveAttendanceSection: false,
    saveModalAttendanceRelease: false,
    changeModelForm: false,
    getAttendanceAnswerPatientById: true,
    getFormAnswerPatient: false,
    saveAttendanceAnswerPatientRelease: false,
    getListCoreLines: false,
    getListCoreLinesByPatientId: false,
    updateCoreLineAttendance: false,
    openCoreLineAttendance: false,
    updateAttendanceCoreLine: false,
    formToPrint: false,
    searchFieldsMedicalRecords: false,
    getDataHistoryByFieldModel: {},
    deleteIndicatorsMedicalRecords: false,
    listIndicatorsDashboardModelsMedicalRecords: false,
  },

  api: {
    attendance: {
      isPublic: true,
      accountId: 0,
      patientId: 0,
      workspaceId: 0,
      userIdCreated: 0,
      medicalRecordsAttendanceId: 0,
      medicalRecordsAccessGroupId: 0,
      startedAt: null,
      createdAt: null,
      releaseAt: null, //Flag para utilizar se foi liberado o atendimento
      exams: [],
      media: [], //Arquivo de Midia
      report: [], //Relatorio
      request: [], //Solicitação
      sections: [],
      referral: [], //Encaminhamento
      attachment: [], //Anexos
      certificate: [], //Atestado
      informations: [],
      prescription: [], //Prescrição
      prescriptionDelta: null, //Objeto delta de uma prescrição
      certificateDelta: null, //Objeto delta de um certificado
      referralDelta: null, //Objeto delta de um encaminhamento
      requestDelta: null, //Objeto delta de uma solicitação
      reportDelta: null, //Objeto delta de um relatório
      sectionsModels: []
    },
    searchFieldsMedicalRecords: [],
    listIndicatorsMedicalRecords: [],
    fieldSelected: {},
    indicatorMedicalRecords: [],
    listIndicatorsDashboardModelsMedicalRecords: [],
    dataFieldModel: {},
    dataHistoryByFieldModel: {},
    attendanceAnswerPatient:{},

    formToPrint: null,

    userAccessGroups: [],

    mainTimeline: {
      medicalRecordsAttendances: [],
      foundMedicalRecordsAccessGroups: [],
      referencedUsers: [],
      dateSearchParam: ''
    },

    examHistory: {
      listRows: [],
      listKeys: {
        withHour: [],
        default: []
      }
    }
  },

  professional:{
    data: {},
    fetching: {
      getProfessional: false
    }
  },

  printIsOpenAttendance: {isOpen: false, data: {}},
  loadingGeneratePdf: {isLoading: false, percentage:0 }
};
