import {createSelector} from 'reselect';

const getCreateContextByKey             = key => state => state.formEngine.create[key];
const getListFieldsContextByKey         = key => state => state.formEngine.create.listFields[key];
const getApiContextByKey                = key => state => state.formEngine.create.api[key];
const getDynamicFormsContextByKey       = key => state => state.formEngine.create.dynamicForms[key];
const getFetchContextByKey              = key => state => state.formEngine.create.fetching[key];
const getDynamicFormsFormsContextByKey  = key => state => state.formEngine.create.dynamicForms.forms[key];

export default ({
  getListFields: createSelector(
    getCreateContextByKey('listFields'),
    state => state
  ),
  getConfigForm: createSelector(
    getCreateContextByKey('configForm'),
    state => state
  ),
  getListFieldsItems: createSelector(
    getListFieldsContextByKey('items'),
    state => state
  ),
  getItems: createSelector(
    getListFieldsContextByKey('items'),
    state => state
  ),
  getFields: createSelector(
    getCreateContextByKey('fields'),
    state => state
  ),
  getImagesFields: createSelector(
    getCreateContextByKey('imagesField'),
    state => state
  ),
  getOriginalListFields: createSelector(
    getApiContextByKey('getListFields'),
    state => state
  ),
  getOriginalListFieldsManager: createSelector(
    getApiContextByKey('getListFieldsManager'),
    state => state
  ),
  getDynamicForms: createSelector(
    getCreateContextByKey('dynamicForms'),
    state => state
  ),
  getDynamicFormsForm: createSelector(
    getDynamicFormsContextByKey('forms'),
    state => state
  ),
  getDynamicFormsModules: createSelector(
    getDynamicFormsContextByKey('modules'),
    state => state
  ),
  getDynamicFormsSections: createSelector(
    getDynamicFormsContextByKey('sections'),
    state => state
  ),
  getDynamicFormsFields: createSelector(
    getDynamicFormsContextByKey('fields'),
    state => state
  ),
  getDynamicFormsSectionsIds: createSelector(
    getDynamicFormsFormsContextByKey('sectionsIds'),
    state => state
  ),
  getModal: createSelector(
    getCreateContextByKey('modal'),
    state => state
  ),
  getIsFetching: createSelector(
    getFetchContextByKey('getListFields'),
    state => state
  ),
  getDynamicFormsDraftId: createSelector(
    getDynamicFormsContextByKey('draftId'),
    state => state
  ),
  getDynamicFormsIdForm: createSelector(
    getDynamicFormsFormsContextByKey('idForm'),
    state => state
  ),
  getDynamicFormsName: createSelector(
    getDynamicFormsFormsContextByKey('name'),
    state => state
  ),
  getDynamicFormsDescription: createSelector(
    getDynamicFormsFormsContextByKey('description'),
    state => state
  ),
  getDynamicFormsAttachPatientModel: createSelector(
    getDynamicFormsFormsContextByKey('attachPatientModel'),
    state => state
  ),
  getFetchingSaveVersionField: createSelector(
    getFetchContextByKey('saveVersionField'),
    state => state
  ),
  getFetchingSaveForms: createSelector(
    getFetchContextByKey('saveForm'),
    state => state
  ),
  getFetchingDraft: createSelector(
    getFetchContextByKey('getDraftById'),
    state => state
  ),
  getFetchingSaveDraft: createSelector(
    getFetchContextByKey('saveDraft'),
    state => state
  ),
  getIsHideAttachPatientModel: createSelector(
    getCreateContextByKey('hideAttachPatientModel'),
    state => state
  ),
  getFormOrigin: createSelector(
    getCreateContextByKey('formOrigin'),
    state => state
  )
});