import initialState from './initialState';

export const FORMS_ANSWER_SET_NAME_CONTEXT = (section, state, action) => {
  const { targetForm, formsId } = action.payload;
  const initialStateForms = {};
  const stateForm = {};
  formsId.forEach(formId => {
    initialStateForms[formId] = { ...initialState }
  })
  const statusForms = {
    statusBarMessage: '',
    enableStatusBar: false,
  }
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: { statusForms, ...initialStateForms },
    },
  };
};

export const FORMS_ANSWER_SET_FIELDS_ERROR = (section, state, action) => {
  const { fieldsError, targetForm } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        errorCalc: fieldsError,
      },
    },
  };
}

export const FORMS_ANSWER_SET_FORM_INITIAL = (section, state, action) => {
  const { targetForm, formId } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: { ...initialState }
      },
    },
  };
};

export const FORMS_ANSWER_SET_FORM_BUILD = (section, state, action) => {
  const {
    targetForm,
    formId,
    form,
    sections,
    modules,
    fields,
    examDataValueId,
    search,
    examsFieldNames,
    reportsIndexed,
    scoresCode,
    formConfig,
  } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          isFirstLoading: false,
          form,
          sections,
          modules,
          fields,
          examDataValueId,
          reportsIndexed,
          scoresCode,
          formConfig,
          api: {
            ...state[section][targetForm].api,
            search,
            examsFieldNames,
          },
        }
      },
    },
  };
};

export const FORMS_ANSWER_SET_FIELDS_FORM = (section, state, action) => {
  const {
    targetForm,
    formId,
    fields,
  } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          fields: {
            ...state[section][targetForm][formId].fields,
            ...fields,
          }
        }
      },
    },
  };
};

export const FORMS_ANSWER_SET_IS_FIELD_EMPTY_FORM = (section, state, action) => {
  const {
    targetForm,
    formId,
    isFieldEmpty
  } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        isFieldEmpty: {
          [formId]: isFieldEmpty
        }
      },
    },
  };
};

export const FORMS_ANSWER_UPDATE_FIELD_BY_ID = (section, state, action) => {
  const { fields, targetForm, formId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          isFirstLoading: false,
          fields,
        }
      },
    },
  };
};

export const FORMS_ANSWER_UPDATE_FIELD_BY_FIELD_ID = (section, state, action) => {
  const { field, targetForm, formId, fieldId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          isFirstLoading: false,
          fields: {
            ...state[section][targetForm][formId].fields,
            [fieldId]: {
              ...field
            }
          }
        }
      },
    },
  };
};

export const FORMS_ANSWER_UPDATE_FIELD_PATIENT_BY_ID = (
  section,
  state,
  action
) => {
  const { patientId, targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        isFirstLoading: false,
        form: {
          ...state[section][targetForm].form,
          patientId,
          fieldPatientIsError: false,
        },
      },
    },
  };
};

export const FORMS_ANSWER_GET_FORM_BY_ID_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  const { targetForm, formId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          enableStatusBar: false,
          statusBarMessage: '',
          fetching: {
            ...state[section][targetForm][formId].fetching,
            getFormById: true,
          },
          form: initialState.form,
          sections: initialState.sections,
          modules: initialState.modules,
          fields: initialState.fields,
          api: {
            search: {},
            examSearch: [],
            examHistory: {
              listRows: [],
              listKeys: {
                withHour: [],
                default: [],
              },
            },
          },
        }
      },
    },
  };
};

export const FORMS_ANSWER_SAVE_FORMS = (section, state, action) => {
  const { done, targetForm, formId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          fetching: {
            ...state[section][targetForm].fetching,
            saveAnswerForm: done,
          },
        }
      },
    },
  };
};

export const FORMS_ANSWER_GET_FORM_BY_ID_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  const { targetForm, formId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          enableStatusBar: true,
          isFirstLoading: false,
          fetching: {
            ...state[section][targetForm][formId].fetching,
            getFormById: false,
          },
        }
      },
    },
  };
};

export const FORMS_ANSWER_GET_FORM_BY_ID_FETCH_ERROR = (
  section,
  state,
  action
) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          getFormById: false,
        },
      },
    },
  };
};

export const FORMS_ANSWER_GET_SEARCH_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  const { idField, targetForm, formId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          fetching: {
            ...state[section][targetForm][formId].fetching,
            search: true,
          },
          api: {
            ...state[section][targetForm][formId].api,
            search: {
              ...state[section][targetForm][formId].api.search,
              [idField]: {
                data: [],
              },
            },
          },
        }
      },
    },
  };
};

export const FORMS_ANSWER_GET_SEARCH_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  const { search, targetForm, formId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          fetching: {
            ...state[section][targetForm][formId].fetching,
            search: false,
          },
          api: {
            ...state[section][targetForm][formId].api,
            search,
          },
        }
      },
    },
  };
};

export const FORMS_ANSWER_GET_SEARCH_FETCH_ERROR = (section, state, action) => {
  const { idField, targetForm, formId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          fetching: {
            ...state[section][targetForm][formId].fetching,
            search: false,
          },
          api: {
            ...state[section][targetForm][formId].api,
            search: {
              ...state[section][targetForm][formId].api.search,
              [idField]: {
                data: [],
              },
            },
          },
        }
      },
    },
  };
};

export const FORMS_ANSWER_SET_RESET_SEARCH = (section, state, action) => {
  const { idField, targetForm, formId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          api: {
            ...state[section][targetForm][formId].api,
            search: {
              ...state[section][targetForm][formId].api.search,
              [idField]: {
                data: [],
              },
            },
          },
        }
      },
    },
  };
};

export const FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          saveProfessional: true,
        },
      },
    },
  };
};

export const FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          saveProfessional: false,
        },
      },
    },
  };
};

export const FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_ERROR = (
  section,
  state,
  action
) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          saveProfessional: false,
        },
      },
    },
  };
};

export const FORMS_ANSWER_CREATE_PATIENT_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          savePatient: true,
        },
      },
    },
  };
};

export const FORMS_ANSWER_CREATE_PATIENT_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          savePatient: false,
        },
      },
    },
  };
};

export const FORMS_ANSWER_CREATE_PATIENT_FETCH_ERROR = (
  section,
  state,
  action
) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          savePatient: false,
        },
      },
    },
  };
};

export const FORMS_ANSWER_SET_SEARCH_EXAM_RESET = (section, state, action) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        api: {
          ...state[section][targetForm].api,
          examSearch: [],
        },
      },
    },
  };
};

export const FORMS_ANSWER_EXAM_FETCH_REQUEST = (section, state, action) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          examSearch: true,
        },
      },
    },
  };
};

export const FORMS_ANSWER_EXAM_FETCH_SUCCESS = (section, state, action) => {
  const { exams, targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          examSearch: false,
        },
        api: {
          ...state[section][targetForm].api,
          examSearch: exams,
        },
      },
    },
  };
};

export const FORMS_ANSWER_EXAM_FETCH_ERROR = (section, state, action) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          examSearch: false,
        },
      },
    },
  };
};

export const FORMS_ANSWER_EXERCISE_FETCH_REQUEST = (section, state, action) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          exerciseSearch: true,
        },
      },
    },
  };
};

export const FORMS_ANSWER_EXERCISE_FETCH_SUCCESS = (section, state, action) => {
  const { exercises, targetForm, formId } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          fetching: {
            ...state[section][targetForm].fetching,
            exerciseSearch: false,
          },
          api: {
            ...state[section][targetForm].api,
            exerciseSearch: exercises,
          },
        }
      },
    },
  };
};

export const FORMS_ANSWER_EXERCISE_FETCH_ERROR = (section, state, action) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          exerciseSearch: false,
        },
      },
    },
  };
};

export const FORMS_ANSWER_SET_SEARCH_EXERCISE_RESET = (
  section,
  state,
  action
) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        api: {
          ...state[section][targetForm].api,
          exerciseSearch: [],
        },
      },
    },
  };
};

export const FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          addExamDynamic: true,
        },
      },
    },
  };
};

export const FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          addExamDynamic: false,
        },
      },
    },
  };
};

export const FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_ERROR = (
  section,
  state,
  action
) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          addExamDynamic: false,
        },
      },
    },
  };
};

export const FORMS_ANSWER_SET_ERROR_FIELD_BY_ID = (section, state, action) => {
  const { idField, isError, targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fields: {
          ...state[section][targetForm].fields,
          [idField]: {
            ...state[section][targetForm].fields[idField],
            isError,
          },
        },
      },
    },
  };
};

export const FORMS_ANSWER_SET_ERROR_FIELD_PATIENT = (
  section,
  state,
  action
) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        form: {
          ...state[section][targetForm].form,
          fieldPatientIsError: true,
        },
      },
    },
  };
};

export const FORMS_ANSWER_ON_CHANGE_REPORT = (section, state, action) => {
  const { inputReport, targetForm, formId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          reportsIndexed: {
            ...state[section][targetForm][formId].reportsIndexed,
            [inputReport.id]: { ...inputReport },
          },
        },
      },
    },
  };
};

export const FORMS_ANSWER_SET_NEW_REPORT_BY_ID = (section, state, action) => {
  const { reportsIndexed, idField, field, targetForm, formId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          reportsIndexed,
          fields: {
            ...state[section][targetForm][formId].fields,
            [idField]: { ...field },
          },
        },

      },
    },
  };
};

export const FORMS_ANSWER_SET_REMOVE_REPORT_BY_ID = (
  section,
  state,
  action
) => {
  const { idField, field, reportsIndexed, targetForm, formId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          reportsIndexed,
          fields: {
            ...state[section][targetForm][formId].fields,
            [idField]: { ...field },
          },
        },

      },
    },
  };
};

export const FORMS_ANSWER_SET_STATUS_BAR_MESSAGE = (section, state, action) => {
  const { message, targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        statusForms: {
          ...state[section][targetForm].statusForms,
          enableStatusBar: true,
          statusBarMessage: message,
        }
      },
    },
  };
};

export const FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          getExamHistory: true,
        },
      },
    },
  };
};

export const FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  const { listKeys, listRows, targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          getExamHistory: false,
        },
        api: {
          ...state[section][targetForm].api,
          examHistory: {
            ...state[section][targetForm].api.examHistory,
            listKeys,
            listRows,
          },
        },
      },
    },
  };
};

export const FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_ERROR = (
  section,
  state,
  action
) => {
  const { targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          getExamHistory: false,
        },
      },
    },
  };
};

export const FORMS_ANSWER_SET_ENABLE_HOUR_TABLE = (section, state, action) => {
  const { enableHour, targetForm } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        enableHour,
      },
    },
  };
};

export const FORMS_ANSWER_SET_URL_IMAGE_GRAPHIC = (section, state, action) => {
  const { urlImageGraphic } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        generateImage: false,
      },
      api: {
        ...state[section].api,
        imageGraph: urlImageGraphic,
      },
    },
  };
}

export const FORMS_ANSWER_FETCH_REQUEST_IMAGE_GRAPHIC = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        generateImage: true,
      },
    },
  };
}

export const FORMS_ANSWER_FETCH_ERROR_IMAGE_GRAPHIC = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        generateImage: false,
      },
    },
  };
}

export const SET_TOKEN_ICD = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      tokenICD: action.payload
    },
  };
}

export const CHANGE_CID_SELECTED = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      cidState: action.payload
    },
  };
};

export const GET_HISTORY_FIELDS_BY_FORM_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  const { targetForm, formId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          fetching: {
            ...state[section][targetForm][formId].fetching,
            getHistoryFieldsByForm: true,
          },
        },
      },
    },
  };
};

export const GET_HISTORY_FIELDS_BY_FORM_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  const { historyFields, targetForm, formId, fieldId, idField } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          fetching: {
            ...state[section][targetForm][formId].fetching,
            getHistoryFieldsByForm: false,
          },
          fields: {
            ...state[section][targetForm][formId].fields,
            [idField]: {
              ...state[section][targetForm][formId].fields[idField],
              historyFields,
            },
          },
          historyFields: {
            ...state[section][targetForm][formId].historyFields,
            [fieldId]: historyFields,
          },
        },
      },
    },
  };
};

export const GET_HISTORY_FIELDS_BY_FORM_FETCH_ERROR = (
  section,
  state,
  action
) => {
  const { targetForm, formId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          fetching: {
            ...state[section][targetForm][formId].fetching,
            getHistoryFieldsByForm: false,
          },
        },
      },
    }
  };
};

export const GET_PAIN_MAP_BODY_AREA_HISTORY_REQUEST = (
  section,
  state,
  action
) => {
  const { targetForm, formId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          fetching: {
            ...state[section][targetForm][formId].fetching,
            getHistoryPainMapBody: true,
          },
        },
      },
    }
  };
};


export const GET_PAIN_MAP_BODY_AREA_HISTORY_SUCCESS = (
  section,
  state,
  action
) => {
  const { historyFields, targetForm, formId, fieldId, idField } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          fetching: {
            ...state[section][targetForm][formId].fetching,
            getHistoryPainMapBody: false,
          },
          fields: {
            ...state[section][targetForm][formId].fields,
            [idField]: {
              ...state[section][targetForm][formId].fields[idField],
              historyFields,
            },
          },
          historyFields: {
            ...state[section][targetForm][formId].historyFields,
            [fieldId]: historyFields,
          },
        },
      },
    },
  };
};

export const GET_PAIN_MAP_BODY_AREA_HISTORY_ERROR = (
  section,
  state,
  action
) => {
  const { targetForm, formId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          fetching: {
            ...state[section][targetForm][formId].fetching,
            getHistoryPainMapBody: false,
          },
        },
      },
    }
  };
};

export const FORMS_UPDATE_FORM_CONFIG = (section, state, action) => {
  const { formConfig, targetForm, formId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          formConfig,
        }
      },
    },
  };
};

export const SET_DATA_GRAPHICS_HISTORY = (section, state, action) => {
  const { dataGraphicsHistory, targetForm, formId, fieldModelId } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          dataGraphicsHistory: {
            ...state[section][targetForm][formId].dataGraphicsHistory,
            [fieldModelId]: dataGraphicsHistory,
          }
        }
      },
    },
  };
};

export const SET_PATH_IMAGE_GRAPHIC_PDF = (section, state, action) => {
  const { key, image } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      imagesPdf: {
        ...state[section].imagesPdf,
        [key]: image,
      }
    },
  };
};

export const GET_IMAGE_FORM_FIELD_FETCH_SUCCESS = (section, state, action) => {
  const { targetForm, formId, idField, base64url, fileReference } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          fields: {
            ...state[section][targetForm][formId].fields,
            [idField]: {
              ...state[section][targetForm][formId].fields[idField],
              images: {
                ...(
                  state[section][targetForm][formId].fields[idField].images || {}
                ),
                [fileReference]: {
                  base64url,
                }
              }
            },
          },
        }
      },
    },
  };
};


export const GET_IMAGE_FORM_FIELD_FETCH_ERROR = (section, state, action) => {
  const { targetForm, formId, idField, fileReference } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          fields: {
            ...state[section][targetForm][formId].fields,
            [idField]: {
              ...state[section][targetForm][formId].fields[idField],
              images: {
                ...state[section][targetForm][formId].fields[idField].images,
                [fileReference]: {
                  base64url: null,
                }
              }
            },
          },
        }
      },
    },
  };
};

export const SET_LIST_CATEGORIES_FILES_FETCH_SUCCESS = (section, state, action) => {

  const { targetForm, categoriesFiles} = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        categoriesFiles,
      }
    },
  };
}

export const SET_LOADING_UPLOAD_IMAGE_INFO_LABEL = (section, state, action) => {
  const { targetForm, formId, status } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        [formId]: {
          ...state[section][targetForm][formId],
          fetching: {
            ...state[section][targetForm][formId].fetching,
            uploadImageInfoLabel: status,
          },
        },
      },
    }
  };
}
