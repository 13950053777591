import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

import { get, del, post, put } from '../util';

const QUALIFIER = 'qualifier';
const FORM = 'form';
const CATEGORIES_FILES = 'categoriesFiles';
const LIST = 'list';

//#region GET
export const getCategories = async ({ header }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${CATEGORIES_FILES}/${LIST}`; 
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getQualifierById = async ({ header, qualifierId }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${QUALIFIER}/${qualifierId}`; 
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};
export const getQualifiersByIds = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${QUALIFIER}/${FORM}`; 
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

//#region POST
export const createCategory = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${CATEGORIES_FILES}`; 
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region PUT
export const updateCategory = async ({ header, params, id }) => {
  const pathUrl = `${endpoints.API_FORMS}/${CATEGORIES_FILES}/${id}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};
//#endregion

//#region DELETE
export const deleteCategory = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${CATEGORIES_FILES}/${params.id}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl);
};