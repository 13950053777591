export const FETCH_SUCCESS_LIST_CATEGORIES = (state, action) => {
    return ({
      ...state,
      medicalRecord: {
        ...state.medicalRecord,
        categories: {
          ...state.medicalRecord.categories,
          fetching: {
            ...state.medicalRecord.categories.fetching,
            getCategories: false
          },
          api:{
            ...state.medicalRecord.categories.api,
            categories: action.payload,
          }
        }
      }
    });
  };

export const FETCH_LIST_CATEGORIES = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      categories: {
        ...state.medicalRecord.categories,
        fetching: {
          ...state.medicalRecord.categories.fetching,
          getCategories: true
        }
      }
    }
  });
}

export const FETCH_LIST_CATEGORIES_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      categories: {
        ...state.medicalRecord.categories,
        fetching: {
          ...state.medicalRecord.categories.fetching,
          getCategories: false
        }
      }
    }
  });
}

export const FETCHING_CATEGORY_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      categories: {
        ...state.medicalRecord.categories,
        fetching: {
          ...state.medicalRecord.categories.fetching,
          saveCategory: true
        }
      }
    }
  });
}

export const FETCHING_CATEGORY_SUCCESS = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      categories: {
        ...state.medicalRecord.categories,
        fetching: {
          ...state.medicalRecord.categories.fetching,
          saveCategory: false
        }
      }
    }
  });
}

export const FETCHING_CATEGORY_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      categories: {
        ...state.medicalRecord.categories,
        fetching: {
          ...state.medicalRecord.categories.fetching,
          saveCategory: false
        }
      }
    }
  });
}